import React, { useState, useRef, useEffect } from 'react';
import './Chatbot.css';

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [beautifiedResponse, setBeautifiedResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // Loading state
  const [rawData, setRawData] = useState(null);  // State to hold raw data
  const chatWindowRef = useRef(null);
  const chatInputRef = useRef(null);
  const chatData2 = ""

  const handleSend = async () => {
    if (input.trim() === '') return;

    // Add the user message to the chat
    const userMessage = { role: 'user', content: input };
    setMessages([...messages, userMessage]);

    // Clear the input field
    setInput('');

    // Set loading state to true
    setIsLoading(true);

    try {
      // Fetch SQL query from workers endpoint
      const workerResponse = await fetch('https://pi360-sql-generator.karan-cse.workers.dev', {
       //const workerResponse = await fetch('https://pi360-sql-gen.ishavverma.workers.dev', {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content: input }),
      });

      if (!workerResponse.ok) {
        throw new Error('Network response was not ok');
      }

      const workerData = await workerResponse.json();
      const botMessage = { role: 'bot', content: workerData.query };

      // Add the bot response to the chat
      setMessages((prevMessages) => [...prevMessages, botMessage]);

      // Base64 encode the query for chat.php endpoint
      const encodedQuery = btoa(workerData.query);

      // Fetch data from chat.php endpoint
      const chatResponse = await fetch(`https://pi360.net/site/api/endpoints/chat.php?institute_id=mietjammu&secret=R0dqSDg3Njc2cC00NCNAaHg=&query=${encodedQuery}`);

      if (!chatResponse.ok) {
        throw new Error('Network response was not ok');
      }

      const chatData = await chatResponse.json();

      if (chatData.response_code === "200") {
        // Replace characters in the response content
        const modifiedResponse = replaceCharacters(JSON.stringify(chatData.content));

        // Send the response to the LLM endpoint for beautification
        const beautifiedResponse = await beautifyResponse(modifiedResponse, input);

        if (beautifiedResponse === 'Error: Unable to beautify response') {
          setRawData(chatData.content);  // Set raw data if beautification fails
        } else {
          setBeautifiedResponse(beautifiedResponse);
        }
      } else {
        setBeautifiedResponse('Error: Unable to fetch data');
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setBeautifiedResponse('Error: Unable to fetch data');
    } finally {
      // Set loading state to false
      setIsLoading(false);
    }
  };

  const replaceCharacters = (responseContent) => {
    // Replace characters as specified
    return responseContent
      .replace(/\{\s*"/g, '(')
      .replace(/"\s*:\s*"/g, '-')
      .replace(/"\s*}/g, ')');
  };

  const beautifyResponse = async (responseContent, userQuery) => {
    try {
      responseContent = "This is JSON response from the database for a natural language query '"+ userQuery +"', beautify it to show relevant information in a natural language to user, avoid any techical keywords :"  + responseContent;
      // Send the response to llama3-endpoint for beautification
      const response = await fetch('https://llm-worker.karan-cse.workers.dev/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          messages: [
            { role: 'system', content: responseContent },
            { role: 'user', content: userQuery }
          ],
        }),
      });

      if (!response.ok) {
        renderTable(chatData2.content);
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return data.response;
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      return 'Error: Unable to beautify response';
    }
  };

  const renderTable = (data) => {
    const keys = Object.keys(data[0]);
    return (
      <table>
        <thead>
          <tr>
            {keys.map((key) => (
              <th key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              {keys.map((key) => (
                <td key={key}>{item[key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  useEffect(() => {
    // Scroll to the bottom of the chat window whenever messages change
    chatWindowRef.current?.scrollTo(0, chatWindowRef.current.scrollHeight);
  }, [messages]);

  return (
    <div className="chatbot-container">
      <div className="chat-and-data-container">
          <div className="chat-window" ref={chatWindowRef}>
          {messages.map((msg, index) => (
            <div key={index} className={`chat-message ${msg.role}`} style={{ display: msg.role === 'bot' ? 'none' : 'block' }}>
              {msg.content}
            </div>
          ))}
        </div>
        <div className="right-response">
          {isLoading ? (
            <div className="loader">Loading...</div>  // Loader element
          ) : (
            <>
              {beautifiedResponse ? <pre>{beautifiedResponse}</pre> : null}
              {rawData ? renderTable(rawData) : null}
            </>
          )}
        </div>
      </div>
      <div className="chat-input" ref={chatInputRef}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message..."
        />
        <button onClick={handleSend}>Send</button>
      </div>
    </div>
  );
};

export default Chatbot;
